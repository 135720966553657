:root {
  --primary-color: #0f0e12;
  --secondary-color: #824bd6;
  --tertiary-color: #3d63ec;
  --bg-color: #f0eeeb;
  --gray: #b6b3c6;
}

:global(.header-xs) {
  font-size: 16px;
  line-height: 20px;
}
:global(.header-sm) {
  font-size: 20px;
  line-height: 24px;
}
:global(.header-md) {
  font-size: 24px;
  line-height: 32px;
}
:global(.header-lg) {
  font-size: 32px;
  line-height: 40px;
}
:global(.header-xl) {
  font-size: 40px;
  line-height: 48px;
}

@media only screen and (min-width: 576px) {
  header {
    @apply header-sm;
  }
}
@media only screen and (min-width: 768px) {
  header {
    @apply header-md;
  }
}
@media only screen and (min-width: 992px) {
  header {
    @apply header-lg;
  }
}
@media only screen and (min-width: 1200px) {
  header {
    @apply header-xl;
  }
}

body,
html {
  font-family: "DM Sans", sans-serif;
  font-size: "16px";
  background-color: #f0eeeb;
}

label {
  font-size: 16px;
  line-height: 20px;
}

button {
  border-radius: 4px;
  background: var(
    --primary-button-bg-enabled-pressed-focused,
    var(--primary-color)
  );
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.25rem;
  font-weight: 600px;
  &:enabled {
    border-radius: var(--radius-sm, 4px);
    background: var(--primary-button-bg-enabled-pressed-focused, #0f0e12);
  }
  &:hover {
    border-radius: var(--radius-sm, 4px);
    background: var(--primary-button-bg-hover, #393545);
  }
  &:active {
    border-radius: var(--radius-sm, 4px);
    background: var(--primary-button-bg-enabled-pressed-focused, #0f0e12);
  }
  &:focus {
    border-radius: var(--radius-sm, 4px);
    background: var(--primary-button-bg-enabled-pressed-focused, #0f0e12);
    box-shadow: 0px 0px 0px 2px #b6b3c6;
  }
  &:disabled {
    border-radius: var(--radius-sm, 4px);
    background: var(--primary-button-bg-disabled, #d8d6e1);
  }
}

:global(.button-large) {
  min-width: 80px;
  padding: 0px 16px;
  gap: 4px;
  font-size: 16px;
  // line-height: 40px;
  min-height: 40px;
  box-shadow: none;
}
:global(.button-medium) {
  min-width: 80px;
  padding: 0px 12px;
  gap: 4px;
  font-size: 16px;
  line-height: 32px;
  box-shadow: none;
}
:global(.button-small) {
  min-width: 56px;
  padding: 0px 8px;
  gap: 4px;
  font-size: 12px;
  line-height: 24px;
  box-shadow: none;
}

:global(.secondary-button) {
  color: #0f0e12;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid
    var(--secondary-button-border-enabled-hover-pressed-focused, #b6b3c6);
  border-radius: var(--radius-sm, 4px);
  background: var(--secondary-button-bg-enabled-pressed-focused, #fff);
  &:enabled {
    border-radius: var(--radius-sm, 4px);
    border: 1px solid
      var(--secondary-button-border-enabled-hover-pressed-focused, #b6b3c6);
    background: var(--secondary-button-bg-enabled-pressed-focused, #fff);
  }
  &:hover {
    border-radius: var(--radius-sm, 4px);
    border: 1px solid
      var(--secondary-button-border-enabled-hover-pressed-focused, #b6b3c6);
    background: var(--secondary-button-bg-hover, rgba(15, 14, 18, 0.05));
  }
  &:active {
    border-radius: var(--radius-sm, 4px);
    border: 1px solid
      var(--secondary-button-border-enabled-hover-pressed-focused, #b6b3c6);
    background: var(--secondary-button-bg-enabled-pressed-focused, #fff);
  }
  &:focus {
    border-radius: var(--radius-sm, 4px);
    border: 1px solid
      var(--secondary-button-border-enabled-hover-pressed-focused, #b6b3c6);
    background: var(--secondary-button-bg-enabled-pressed-focused, #fff);
    /* Button Focus Shadow */
    box-shadow: 0px 0px 0px 2px #b6b3c6;
  }
  &:disabled {
    color: var(--Secondary-Button-Disabled, #b6b3c6);
    border-radius: var(--radius-sm, 4px);
    border: 1px solid var(--secondary-button-disabled, #b6b3c6);
    background: var(--secondary-button-bg-enabled-pressed-focused, #fff);
  }
}

.secondary-button-disabled {
  color: var(--Secondary-Button-Disabled, #b6b3c6);
  border-radius: var(--radius-sm, 4px);
  border: 1px solid var(--secondary-button-disabled, #b6b3c6);
  background: var(--secondary-button-bg-enabled-pressed-focused, #fff);
}

:global(.tertiary-button) {
  border-radius: 4px;
  border: none;
  background: #fff;
  color: #0f0e12;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:enabled {
    border-radius: var(--radius-sm, 4px);
    background: var(--tertiary-button-bg, rgba(255, 255, 255, 0));
  }
  &:hover {
    border-radius: var(--radius-sm, 4px);
    background: var(--tertiary-button-icon-hover, rgba(15, 14, 18, 0.05));
  }
  &:active {
    border-radius: var(--radius-sm, 4px);
    background: var(--tertiary-button-bg, rgba(255, 255, 255, 0));
  }
  &:focus {
    border-radius: var(--radius-sm, 4px);
    background: var(--tertiary-button-bg, rgba(255, 255, 255, 0));

    /* Button Focus Shadow */
    box-shadow: 0px 0px 0px 2px #b6b3c6;
  }
  &:disabled {
    border-radius: var(--radius-sm, 4px);
    background: var(--secondary-button-bg-enabled-pressed-focused, #fff);
  }
}

:global(.destructive-button) {
  border-radius: 4px;
  background-color: #dd2525;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:enabled {
    border-radius: var(--radius-sm, 4px);
    background: var(--error-red-600, #dd2525);
  }
  &:hover {
    border-radius: var(--radius-sm, 4px);
    background: var(--error-red-700, #ba1b1b);
  }
  &:active {
    border-radius: var(--radius-sm, 4px);
    background: var(--error-red-600, #dd2525);
  }
  &:focused {
    border-radius: var(--radius-sm, 4px);
    background: var(--error-red-600, #dd2525);

    /* Button Focus Shadow */
    box-shadow: 0px 0px 0px 2px #b6b3c6;
  }
  &:disabled {
    border-radius: var(--radius-sm, 4px);
    background: var(--primary-button-bg-disabled, #d8d6e1);
  }
}

:global(.icon-lg) {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 8px;
}

:global(.icon-md) {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
}

:global(.icon-sm) {
  display: flex;
  padding: 6px;
  align-items: flex-start;
  gap: 8px;
}

:global(.icon-primary) {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 8px;
  &:enabled {
    border-radius: var(--radius-sm, 4px);
    background: var(--primary-button-bg-enabled-pressed-focused, #0f0e12);
  }
  &:hover {
    border-radius: var(--radius-sm, 4px);
    background: var(--primary-button-bg-hover, #393545);
  }
  &:active {
    border-radius: var(--radius-sm, 4px);
    background: var(--primary-button-bg-enabled-pressed-focused, #0f0e12);
  }
  &:focused {
    border-radius: var(--radius-sm, 4px);
    background: var(--primary-button-bg-enabled-pressed-focused, #0f0e12);

    /* Button Focus Shadow */
    box-shadow: 0px 0px 0px 2px #b6b3c6;
  }
  &:disabled {
    border-radius: var(--radius-sm, 4px);
    background: var(--primary-button-bg-disabled, #d8d6e1);
  }
}

:global(.icon-secondary) {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 8px;
  border-radius: var(--radius-sm, 4px);
  border: 1px solid
    var(--secondary-button-border-enabled-hover-pressed-focused, #b6b3c6);
  background: var(--secondary-button-bg-enabled-pressed-focused, #fff);
}

:global(.icon-tertiary) {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 8px;
  border-radius: var(--radius-sm, 4px);
  background: var(--tertiary-button-bg, rgba(255, 255, 255, 0));
}

input {
  width: 100%;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid var(--input-base-border-enabled-filled, #b6b3c6);
  background: var(--input-base-bg-enabled-hover-focused-filled-error, #fff);
  appearance: none;
}

textarea {
  display: flex;
  width: 100%;
  padding: 8px 16px 16px 16px;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid var(--midnight-black-300, #b6b3c6);
  background: var(--input-base-bg-enabled-hover-focused-filled-error, #fff);
}

select {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--input-base-border-enabled-filled, #b6b3c6);
  width: 100%;
}

:global(.input-radio) {
  display: flex;
  padding: 4px 12px 12px 4px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid
    var(--input-radio-checkbox-border-hover-focused, var(--secondary-color));
  background: var(
    --input-radio-checkbox-bg-enabled-hover-focused-filled-error,
    #fff
  );
  color: transparent;
  &:hover {
    border-radius: 12px;
    border: 1px solid
      var(--input-radio-checkbox-border-hover-focused, var(--secondary-color));
  }
}
:global(.input-radio:checked) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Ccircle cx='8' cy='8' r='5' fill='%23824BD6'/%3E%3C/svg%3E");
  border-radius: 12px;
  border: 1px solid
    var(--input-radio-checkbox-border-hover-focused, var(--secondary-color));
}
:global(.input-radio:focus) {
  box-shadow: 0px 0px 0px 2px #b6b3c6;
  border-radius: 12px;
  border: 1px solid
    var(--input-radio-checkbox-border-hover-focused, var(--secondary-color));
}
:global(.input-radio:checked:hover) {
  border-radius: 12px;
  border: 1px solid var(--input-radio-checkbox-disabled, var(--secondary-color));
}

:global(.input-checkbox) {
  display: flex;
  padding: 4px 12px 12px 4px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--gray);
  background: var(
    --input-radio-checkbox-bg-enabled-hover-focused-filled-error,
    #fff
  );
  color: transparent;
  &:hover {
    border-radius: 4px;
    border: 1px solid var(--secondary-color);
    cursor: pointer;
  }
}
:global(.input-checkbox:checked) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.8047 3.52858C14.0651 3.78892 14.0651 4.21103 13.8047 4.47138L6.4714 11.8047C6.21106 12.0651 5.78894 12.0651 5.5286 11.8047L2.19526 8.47138C1.93491 8.21103 1.93491 7.78892 2.19526 7.52858C2.45561 7.26823 2.87772 7.26823 3.13807 7.52858L6 10.3905L12.8619 3.52858C13.1223 3.26823 13.5444 3.26823 13.8047 3.52858Z' fill='%23824BD6'/%3E%3C/svg%3E");
  border-radius: 4px;
  border: 1px solid var(--secondary-color);
}
:global(.input-checkbox:focus) {
  box-shadow: 0px 0px 0px 2px #b6b3c6;
  border-radius: 4px;
  border: 1px solid var(--secondary-color);
}
:global(.input-checkbox:checked:hover) {
  border-radius: 4px;
  border: 1px solid var(--secondary-color);
}

:global(.shadow) {
  box-shadow: 0px 4px 0px 0px var(--primary-color);
  border-radius: var(--radius-sm, 8px);
  border: 1px solid var(--content-card-border-dropshadow, var(--primary-color));
}

:global(.background-styles) {
  background: var(--bg-color);
  height: 100%;
  width: 100%;
}

.app {
  font-family: sans-serif;
  min-width: 300px;
  max-width: 600px;
  margin: 50px auto;
}

.app :global(.gutter-left) {
  margin-left: 9px;
}

.app :global(.col-span-2) {
  grid-column: span 2;
}

.app :global(.flex) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.app :global(header) {
  background-color: #143055;
  color: white;
  padding: 5px;
  border-radius: 3px;
}

.app :global(main) {
  padding: 0 36px;
}

.app :global(p) {
  text-align: center;
}

.app :global(h1) {
  text-align: center;
  margin-left: 18px;
  font-size: 24px;
}

.app :global(h2) {
  text-align: center;
  font-size: 20px;
  margin: 40px 0 10px 0;
}

.app :global(.resources) {
  text-align: center;
  list-style: none;
  padding: 0;
  display: grid;
  grid-gap: 9px;
  grid-template-columns: 1fr 1fr;
}

.app :global(.resource) {
  color: #0094ba;
  height: 36px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 3px 9px;
  text-decoration: none;
}

.app :global(.resource:hover) {
  background-color: rgba(68, 138, 255, 0.04);
}

.app :global(pre) {
  padding: 9px;
  border-radius: 4px;
  background-color: black;
  color: #eee;
}

.app :global(details) {
  border-radius: 4px;
  color: #333;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 3px 9px;
  margin-bottom: 9px;
}

.app :global(summary) {
  outline: none;
  height: 36px;
  line-height: 36px;
}

.app :global(.github-star-container) {
  margin-top: 12px;
  line-height: 20px;
}

.app :global(.github-star-container a) {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
}

.app :global(.github-star-badge) {
  color: #24292e;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 3px 10px;
  border: 1px solid rgba(27, 31, 35, 0.2);
  border-radius: 3px;
  background-image: linear-gradient(-180deg, #fafbfc, #eff3f6 90%);
  margin-left: 4px;
  font-weight: 600;
}

.app :global(.github-star-badge:hover) {
  background-image: linear-gradient(-180deg, #f0f3f6, #e6ebf1 90%);
  border-color: rgba(27, 31, 35, 0.35);
  background-position: -0.5em;
}
.app :global(.github-star-badge .material-icons) {
  height: 16px;
  width: 16px;
  margin-right: 4px;
}
